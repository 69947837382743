$(document).on('click', '#verify-code #verify-code-button', function(event) {
  $('#verify-code button').prop('disabled', true);
  var value = {};
  value.verification_code_password = $('#verify-code input[name=code]').val();
  initialApiRestrict('/forgot_password/check', value, 'post').done(function(json) {
      if (json.status == "600") {
        $('#reset-pass').attr('data-id', value.verification_code_password);
        $('#verify-code').parent().addClass('collapse');
        $('#reset-pass').parent().removeClass('collapse');
      }else{
        $('#verify-code-message').html(json.error).fadeIn('slow', function(){
          setTimeout(function(){ $('#verify-code-message').fadeOut(5000) }, 1000);
        })
          // $('.modal-wt-error').show(0).delay(2000).hide(0);
          // $('.modal-wt-error .inner').html(json.error);
      }
  }).always(function() {
      $('#verify-code button').removeAttr('disabled');
  });
});

$('#overlay-verify-code').click(function(event) {
    if (event.target === this) {
        $('#verify-code').parent().addClass('collapse');
    }
});
$('#verify-code .close-modal').click(function(event) {
    $('#verify-code').parent().addClass('collapse');
});
